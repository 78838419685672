import React from 'react'
import Header from '../Header/Header'
import './Hero.css'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import heart from '../../assets/heart.png'
import calories from '../../assets/calories.png'
import { motion } from "framer-motion"
import CountUp from 'react-countup';

const Hero = () => {
  const transition = { type: 'spring', duration: 3 }
  const mobile = window.innerWidth <= 768 ? true : false;
  return (
    <div className="hero" id='home'>
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile ? '165px' : '238px' }}
            whileInView={{ left: '8px' }}
            transition={{ ...transition, type: 'tween' }}
          ></motion.div>
          <span>The Best Fitness Club in the town</span>
        </div>
        <div className="hero-text">
          <div >
            <span className='stroke-text'>Shape </span>
            <span>Your</span>
          </div>
          <div >
            <span>Ideal Body</span>
          </div>
          <div>
            <span>In here we will help you to shape and build your ideal body and live up your life to fullest</span>
          </div>
        </div>
        <div className="figures">
          <div><span>
            <CountUp start={100} end={140} delay={0.3} prefix="+" />
          </span>
            <span>EXPERT COACHES</span>
          </div>
          <div><span><CountUp start={900} end={978} delay={0.3} prefix="+" /></span>
            <span>MEMBERS JOINED</span>
          </div>
          <div><span><CountUp start={0} end={50} delay={0.3} prefix="+" /></span>
            <span>FITNESS PROGRAMS</span>
          </div>
        </div>
        <div className="hero-buttons">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>
      <div className="right-h">
        <button className='btn'>Join Now</button>
        <motion.div
          initial={{ right: '-1rem' }}
          transition={transition}
          whileInView={{ right: '4rem' }}
          className="heart-rate"
        >
          <img src={heart} alt="Heart-rate-images" />
          <span>Heart Rate</span>
          <span>116 BPM</span>
        </motion.div>
        <img
          src={hero_image} alt="hero_image" className='hero-image' />
        <motion.img
          initial={{ right: '11rem' }}
          transition={transition}
          whileInView={{ right: '20rem' }}
          src={hero_image_back} alt="hero_img_background" className='hero-image-back' />

        <motion.div
          initial={{ right: '37rem' }}
          transition={transition}
          whileInView={{ right: '28rem' }}
          className="calories">
          <img src={calories} alt="calories" />
          <div>
            <span>Calories Burned</span>
            <span>220 kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Hero
